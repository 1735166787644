.products {
  display: flex;
  flex-wrap: wrap;
  flex-flow: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 5rem 0;
}

.product {
  &--icon {
    font-size: 60px;
    color: transparentize($primary, 0.5);
  }

  &--description {
    font-size: 18px;
    text-align: justify;
    padding: 0 30px;
    margin-top: 30px;
  }
}

@media screen and (max-width: 768px) {
  .products {
    flex-direction: column;
  }
}