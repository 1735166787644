.trailers {
  position: relative;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.trailer {
  margin-bottom: 100px;
  display: flex;
  flex-flow: column;
  width: 400px;
  overflow-x: clip;

  &-type {
    text-align: left;
    font-style: italic;
  }

  &-title {
    text-align: left;
    font-size: 2.2rem;
    margin-bottom: 20px;
    margin-top: 5px;
  }

  &-details {
    text-align: left;
    line-height: 0.75rem;
    font-size: 16px;

    & > p > span {
      font-size: 1rem;
      font-style: italic;
    }
  }

  &--image {
    width: 400px;
    height: 250px;
    overflow: hidden;

    & > img {
      width: 100%;
      height: 100%;
      transform: scale(1.5);
      transition: .3s ease-in-out;

      &:hover {
        transform: scale(1);
      }
    }
  }

  &-price {
    width: 150px;
    height: 50px;
    background-color: transparentize(#313136, 0.2);
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.25);
    color: #fff;
    margin-left: auto;
    margin-right: auto;
    margin-top: 13px;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding-top: 18px;
  }

  &--text {
    z-index: 1;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.5);
    margin: -30px auto;
    height: 270px;
    width: 300px;
    background-color: #313136;
    color: #FFFFFF;
    padding: 20px;
    border-radius: 5px;
  }
}