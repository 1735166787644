@import "trailerCard";
@import "modal";
@import "container";
@import "product";

.content {
  &-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &_title {
      color: #ffffff;
      font-size: 36px;
    }

    &_text {
      color: #ffffff;
      font-size: 18px;
    }

    &_content > img {
      width: 300px;
    }
  }
}

.container-slanted {
  background: linear-gradient(90deg, $secondary, $primary);
  color: white;
  padding: 5rem 10%;
  position: relative;

  &__right {
    clip-path: polygon(0 0, 100% 20%, 100% 100%, 0 80%);
  }

  &__left {
    clip-path: polygon(0 20%, 100% 0, 100% 80%, 0 100%);
  }
}

h3 {
  font-weight: normal;
}

.section-heading {
  position: relative;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: 160px;
  text-align: center;
  margin: auto;
  white-space: nowrap;
  padding-bottom: 13px;

  &:before {
    background-color: $primary;
    content: '';
    display: block;
    height: 6px;
    width: 75px;
    margin-bottom: 5px;
  }

  &:after {
    background-color: $secondary;
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 6px;
    width: 75px;
    margin-bottom: 0.25em;
  }
}

.pull-right {
  float: right;
}

.heading--bold {
  font-weight: bold;
  font-size: 20px;
  color: #808B95;
}

ul {
  list-style-type: square;
}

li::marker {
  color: $primary;
}

#navbarText {
  justify-content: end;
}

.justify-text {
  text-align: justify;
}

.google--map {
  width: 100%;
  height: 500px;
}

.tire-background {
  background-image: url('../../public/img/tire.jpg');
}

.wuchten-background {
  background-image: url('../../public/img/wuchten.jpeg');
}

.montieren-background {
  background-image: url('../../public/img/montieren.jpeg');
}

.background-primary {
  background-color: $primary !important;
}