.container-background {
  background-size: cover;
  height: 480px;
  background-position: center;
}

.clipped-containers > div:nth-child(even) > .clipped-container {
  clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);
}

.clipped-containers > div:nth-child(odd) > .clipped-container {
  clip-path: polygon(0 0%, 100% 10%, 100% 100%, 0 90%);
}