.modal-content {
  border-color: transparent !important;
}

.modal-body a {
  text-decoration: none;
  color: #ffffff;
}

.close {
  border-color: transparent;
  border-radius: 2px;
}